import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

export async function logData(props) {
  const TextCell = ({ text, color, err_check }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography component="a" href="#" variant="caption" color={color || "text"} fontWeight="medium">
            {err_check?(
              text?"success":"error"
            ):text}
          </MDTypography>
      </MDBox>
    </MDBox>
  );

  function setTextColor(status){
    return status?"success":"error"
  }

  function setRowData() {
    const data = props;
    let array = [];
    data.forEach(function(rows){
      var rowObj = {
        company_name: <TextCell text={rows.company_name} id={rows.company_id} name={rows.company_name} color={"text"} />,
        company_id: <TextCell text={rows.company_id} name={rows.company_name}  color={"text"} />,
        store_id: <TextCell text={rows.store_id} id={rows.store_id} name={rows.store_name} color={"text"} />,
        store_name: <TextCell text={rows.store_name} color={"text"} />,
        device_id: <TextCell text={rows.device_id} color={"text"}/>,
        deploy_last_upd: <TextCell text={rows.last_upd} color={"text"} />,
        file_name: <TextCell text={rows.file_name} color={"text"} />,
        file_check: <TextCell text={rows.file_check} color={setTextColor(rows.file_check)} err_check={true}/>,
        run_check: <TextCell text={rows.run_check} color={setTextColor(rows.run_check)} err_check={true}/>,
        scp_check: <TextCell text={rows.scp_check} color={setTextColor(rows.scp_check)} err_check={true}/>,
        status_check: <TextCell text={rows.status_check} color={setTextColor(rows.status_check)} err_check={true}/>,
      }
      array.push(rowObj)
    })
    return array
  }

  return {
    columns: [
      { Header: "회사명", accessor: "company_name", align: "center" },
      { Header: "회사 ID", accessor: "company_id", align: "center" },
      { Header: "지점명", accessor: "store_name", align: "center" },
      { Header: "지점 ID", accessor: "store_id", align: "center" },
      { Header: "디바이스 ID", accessor: "device_id", align: "center" },
      { Header: "배포 날짜", accessor: "deploy_last_upd", align: "center" },
      { Header: "파일명", accessor: "file_name", align: "center" },
      { Header: "파일 체크 오류", accessor: "file_check", align: "center" },
      { Header: "고객 사용중 오류", accessor: "run_check", align: "center" },
      { Header: "SCP 오류", accessor: "scp_check", align: "center" },
      { Header: "상태 체크 오류", accessor: "status_check", align: "center" },
    ],
    rows: setRowData()
  };
}
