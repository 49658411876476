import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export async function deployOptionData(props, onButton) {
  function TextCell({ text, color, options }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color={color || "text"}
            fontWeight="medium"
          >
            {options == "err_check"
              ? text
                ? "success"
                : text == false
                ? "error"
                : "x"
              : options == "opt_check"
              ? text
                ? "on"
                : "off"
              : text || "X"}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function Button({ rows, target }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDButton
            component="a"
            href="#"
            variant="gradient"
            color={rows[target] ? "white" : "success"}
            fontWeight="medium"
            onClick={() => onButton(rows, rows[target] ? "false" : "true", target)}
          >
            {rows[target] ? "On" : "Off"}
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }

  function setStatusColor(status) {
    return status == "F" ? "error" : status == "C" ? "warning" : status == "S" ? "success" : "text";
  }

  function deployStatus(status) {
    return status == "S" ? "OK" : status == "C" ? "CONTINUE" : status == "F" ? "FAIL" : "NULL";
  }

  function setRowData() {
    const data = props;
    const array = [];
    data.forEach((rows) => {
      const rowObj = {
        company_name: <TextCell text={rows.company_name} color="text" />,
        company_id: <TextCell text={rows.company_id} name={rows.company_name} color="text" />,
        store_name: <TextCell text={rows.store_name} color="text" />,
        store_id: <TextCell text={rows.store_id} name={rows.store_name} color="text" />,
        device_id: <TextCell text={rows.device_id} color="text" />,
        deploy_status: (
          <TextCell
            text={deployStatus(rows.deploy_status)}
            color={setStatusColor(rows.deploy_status)}
          />
        ),
        deploy_option: <Button rows={rows} target="deploy_option" />,
        kiosk_deploy_option: <Button rows={rows} target="kiosk_deploy_option" />,
      };
      array.push(rowObj);
    });
    return array;
  }

  return {
    columns: [
      { Header: "회사명", accessor: "company_name", align: "center" },
      { Header: "회사 ID", accessor: "company_id", align: "center" },
      { Header: "지점명", accessor: "store_name", align: "center" },
      { Header: "지점 ID", accessor: "store_id", align: "center" },
      { Header: "배포 상태", accessor: "deploy_status", align: "center" },
      { Header: "지점 배포 옵션", accessor: "deploy_option", align: "center" },
      { Header: "키오스크 배포 옵션", accessor: "kiosk_deploy_option", align: "center" },
    ],
    rows: setRowData(),
  };
}
