import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Data
import { useEffect, useState } from "react";
import {
  getDeployFlag,
  getDeployMemo,
  updateDeployRedis,
  getKioskDeployFlag,
} from "function/Deploy";

function DeployFlag() {
  const [deploy_flag, setDeployFlag] = useState(false);
  const [kiosk_deploy_flag, setKioskDeployFlag] = useState(false);
  const [deploy_memo, setDeployMemo] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  async function flagButtonHandler(values, t) {
    const res = await updateDeployRedis(t, values);
    if (res == "OK") {
      setRefreshKey((oldKey) => oldKey + 1);
      alert(
        `전지점 ${t === "kiosk_deploy_flag" ? "키오스크 " : ""}배포 여부 ${
          values ? "True" : "False"
        }설정`
      );
    } else {
      alert(`실패 ${res}`);
    }
  }

  async function memoButtonHandler(values) {
    const res = await updateDeployRedis("deploy_memo", values);
    if (res == "OK") {
      setRefreshKey((oldKey) => oldKey + 1);
      alert(`메모 변경 완료 : ${values}`);
    } else {
      alert(`실패 ${res}`);
    }
  }

  useEffect(() => {
    async function fetchData() {
      const flag = await getDeployFlag();
      const memo = await getDeployMemo();
      const kioskFlag = await getKioskDeployFlag();
      setDeployFlag(flag);
      setDeployMemo(memo);
      setKioskDeployFlag(kioskFlag);
    }
    fetchData();
  }, [refreshKey]);

  return (
    <Card>
      <Grid container spacing={3} m={2}>
        <MDBox pt={3}>
          <MDTypography variant="h6">
            전지점 배포 여부(deploy_flag) : {deploy_flag == 1 ? "True" : "False"}
          </MDTypography>
          <MDButton
            variant="gradient"
            style={{ width: 200 }}
            color={deploy_flag == 1 ? "primary" : "success"}
            onClick={(e) => flagButtonHandler(deploy_flag == 1 ? 0 : 1, "deploy_flag")}
          >
            {deploy_flag == 1 ? "False 변경" : "True 변경"}
          </MDButton>
        </MDBox>
      </Grid>

      <Grid container spacing={3} m={2}>
        <MDBox>
          <MDTypography variant="h6">
            전지점 키오스크 배포 여부(kiosk_deploy_flag) :{" "}
            {kiosk_deploy_flag == 1 ? "True" : "False"}
          </MDTypography>
          <MDButton
            variant="gradient"
            style={{ width: 200 }}
            color={kiosk_deploy_flag == 1 ? "primary" : "success"}
            onClick={(e) => flagButtonHandler(kiosk_deploy_flag == 1 ? 0 : 1, "kiosk_deploy_flag")}
          >
            {kiosk_deploy_flag == 1 ? "False 변경" : "True 변경"}
          </MDButton>
        </MDBox>
      </Grid>

      <Grid item xs={12} m={2}>
        <MDInput
          m={2}
          style={{ backgroundColor: "lavender" }}
          label="전달사항 작성"
          variant="outlined"
          fullWidth
          value={deploy_memo}
          onChange={(e) => setDeployMemo(e.target.value)}
          multiline
        />
        <MDButton
          p={2}
          variant="gradient"
          color="info"
          onClick={(e) => memoButtonHandler(deploy_memo)}
        >
          {" "}
          메모 저장
        </MDButton>
      </Grid>
    </Card>
  );
}

export default DeployFlag;
