import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// daterangepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";

// Data
import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button } from "@material-ui/core";
import { updateDeployOptions } from "../functions";
import { getKioskDeployLog } from "../../function/Deploy";

registerLocale("ko", ko);

const moment = require("moment");

function kiosk() {
  const [originKioskLogs, setOriginKioskLogs] = useState([]);
  const [kioskLogs, setKioskLogs] = useState([]);

  const [allStore, setAllStore] = useState([]);
  const [allDevice, setAllDevice] = useState([]);
  const [filterStore, setFilterStore] = useState("");
  const [filterDevice, setFilterDevice] = useState("");

  const [selectedTime, SetSelectedTime] = useState({
    startDate: new Date(moment().startOf("month").toDate()),
    endDate: new Date(moment().startOf("day").toDate()),
  });

  const styles = {
    cellStyle: {
      fontSize: 12,
      color: "#6f6f6f",
      fontWeight: 500,
    },
  };

  // 필터에 쓸 스토어 디바이스 구하기
  useEffect(() => {
    setAllStore(
      [
        ...new Set(
          originKioskLogs.map((d) =>
            JSON.stringify({
              store_id: d.store_id,
              store_name: d.store_name,
            })
          )
        ),
      ].map((d) => JSON.parse(d))
    );
    setAllDevice(
      [
        ...new Set(
          originKioskLogs.map((d) =>
            JSON.stringify({
              device_id: d.device_id,
            })
          )
        ),
      ].map((d) => JSON.parse(d))
    );
  }, [originKioskLogs]);

  useEffect(() => {
    const onLoad = async () => {
      const res = await getKioskDeployLog();

      setOriginKioskLogs(
        res.map((d) => ({
          ...d,
          company: `${d.company_name} ( ${d.company_id} )`,
          store: `${d.store_name} ( ${d.store_id} )`,
          deploy_date_time: `${
            d.deploy_date?.length === 8
              ? `${d.deploy_date.substring(0, 4)}년 ${d.deploy_date.substring(
                  4,
                  6
                )}월 ${d.deploy_date.substring(6, 8)}일`
              : d.deploy_date
          }\n${
            d.deploy_time?.length === 4
              ? `${d.deploy_time.substring(0, 2)}시 ${d.deploy_time.substring(2, 4)}분`
              : d.deploy_time
          }`,
          force_value_custom: d.force_value ? "On" : "Off",
          download_check_custom: d.download_check ? "Success" : "Fail",
          apply_check_custom: d.apply_check ? "Success" : "Fail",
        }))
      );

      setKioskLogs(
        res.map((d) => ({
          ...d,
          company: `${d.company_name} ( ${d.company_id} )`,
          store: `${d.store_name} ( ${d.store_id} )`,
          deploy_date_time: `${
            d.deploy_date?.length === 8
              ? `${d.deploy_date.substring(0, 4)}년 ${d.deploy_date.substring(
                  4,
                  6
                )}월 ${d.deploy_date.substring(6, 8)}일`
              : d.deploy_date
          }\n${
            d.deploy_time?.length === 4
              ? `${d.deploy_time.substring(0, 2)}시 ${d.deploy_time.substring(2, 4)}분`
              : d.deploy_time
          }`,
          force_value_custom: d.force_value ? "On" : "Off",
          download_check_custom: d.download_check ? "Success" : "Fail",
          apply_check_custom: d.apply_check ? "Success" : "Fail",
        }))
      );
    };
    onLoad();
  }, []);

  const SelcetDateCallback = (event, values) => {
    SetSelectedTime({ startDate: values.startDate.toDate(), endDate: values.endDate.toDate() });
  };

  function leftPad(value) {
    if (value >= 10) {
      return value;
    }

    return `0${value}`;
  }

  function toStringByFormatting(source) {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());

    return [year, month, day].join("");
  }

  useEffect(() => {
    let origin = [...originKioskLogs];

    origin = origin.filter(
      (d) =>
        d.deploy_date >= toStringByFormatting(selectedTime.startDate) &&
        d.deploy_date <= toStringByFormatting(selectedTime.endDate)
    );

    if (filterStore) {
      origin = origin.filter((d) => d.store_id === filterStore.store_id);
      setAllDevice(
        [
          ...new Set(
            origin.map((d) =>
              JSON.stringify({
                device_id: d.device_id,
              })
            )
          ),
        ].map((d) => JSON.parse(d))
      );
    } else {
      setAllDevice(
        [
          ...new Set(
            originKioskLogs.map((d) =>
              JSON.stringify({
                device_id: d.device_id,
              })
            )
          ),
        ].map((d) => JSON.parse(d))
      );
    }
    if (filterDevice) origin = origin.filter((d) => d.device_id === filterDevice.device_id);

    setKioskLogs(origin);
  }, [selectedTime, filterDevice, filterStore, originKioskLogs]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  키오스크
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} m={2} xs={12}>
                <Grid item>
                  <DateRangePicker initialSettings={selectedTime} onApply={SelcetDateCallback}>
                    <input
                      type="text"
                      className="form-control"
                      value={`${moment(selectedTime.startDate).format("YYYY/MM/DD")} - ${moment(
                        selectedTime.endDate
                      ).format("YYYY/MM/DD")}`}
                      style={{ display: "block", width: 300, height: 20, padding: 25 }}
                    />
                  </DateRangePicker>
                </Grid>

                <Grid item>
                  <Autocomplete
                    options={allStore}
                    getOptionLabel={(option) => `${option.store_name}(${option.store_id})`}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="스토어 필터" variant="outlined" />
                    )}
                    value={filterStore || null}
                    onChange={(event, values) => setFilterStore(values)}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    options={allDevice}
                    getOptionLabel={(option) => option.device_id}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="디바이스 필터" variant="outlined" />
                    )}
                    value={filterDevice || null}
                    onChange={(event, values) => setFilterDevice(values)}
                  />
                </Grid>
              </Grid>
              <DataTable
                canSearch
                table={{
                  columns: [
                    {
                      Header: "회사명",
                      accessor: "company",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "지점명",
                      accessor: "store",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "디바이스",
                      accessor: "device_id",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "파일명",
                      accessor: "file_name",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "해시",
                      accessor: "mdfive",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "배포시각",
                      accessor: "deploy_date_time",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "강제 옵션",
                      accessor: "force_value_custom",
                      align: "center",
                      Cell: ({ value }) => <div style={styles.cellStyle}>{value}</div>,
                    },
                    {
                      Header: "다운로드 확인",
                      accessor: "download_check_custom",
                      align: "center",
                      Cell: ({ value }) => (
                        <div
                          style={{
                            ...styles.cellStyle,
                            color: value === "Success" ? "blue" : "red",
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                    {
                      Header: "업데이트 확인",
                      accessor: "apply_check_custom",
                      align: "center",
                      Cell: ({ value }) => (
                        <div
                          style={{
                            ...styles.cellStyle,
                            color: value === "Success" ? "blue" : "red",
                          }}
                        >
                          {value}
                        </div>
                      ),
                    },
                  ],
                  rows: kioskLogs,
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default kiosk;
