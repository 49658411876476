import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export async function deployOptionData(props, onButton) {
  function TextCell({ text, color, options }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color={color || "text"}
            fontWeight="medium"
          >
            {options == "err_check"
              ? text
                ? "success"
                : text == false
                ? "error"
                : "x"
              : options == "opt_check"
              ? text
                ? "on"
                : "off"
              : text || "X"}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function setRowData() {
    const data = props;
    const array = [];
    data.forEach((rows) => {
      const rowObj = {
        company_id: <TextCell text={rows.company_id} color="text" />,
        company_name: <TextCell text={rows.company_name} name={rows.company_name} color="text" />,
        store_count: <TextCell text={rows.store_count} color="text" />,
        store_count_true: <TextCell text={rows.store_count_true} color="text" />,
        store_count_false: <TextCell text={rows.store_count_false} color="text" />,
        device_count: <TextCell text={rows.device_count} color="text" />,
        device_count_true: <TextCell text={rows.device_count_true} color="text" />,
        device_count_false: <TextCell text={rows.device_count_false} color="text" />,
        deploy_status_s: <TextCell text={rows.deploy_status_s} color="text" />,
        deploy_status_c: <TextCell text={rows.deploy_status_c} color="text" />,
        deploy_status_f: <TextCell text={rows.deploy_status_f} color="text" />,
        deploy_status_null: <TextCell text={rows.deploy_status_null} color="text" />,
      };
      array.push(rowObj);
    });
    return array;
  }

  return {
    columns: [
      { Header: "회사명", accessor: "company_name", align: "center" },
      { Header: "회사 ID", accessor: "company_id", align: "center" },
      { Header: "지점 개수", accessor: "store_count", align: "center" },
      { Header: "배포 ON 지점", accessor: "store_count_true", align: "center" },
      { Header: "배포 OFF 지점", accessor: "store_count_false", align: "center" },
      { Header: "디바이스 개수", accessor: "device_count", align: "center" },
      { Header: "배포 ON 디바이스", accessor: "device_count_true", align: "center" },
      { Header: "배포 OFF 디바이스", accessor: "device_count_false", align: "center" },
      { Header: "배포 성공 디바이스(S)", accessor: "deploy_status_s", align: "center" },
      { Header: "배포중 디바이스(C)", accessor: "deploy_status_c", align: "center" },
      { Header: "배포 실패 디바이스(F)", accessor: "deploy_status_f", align: "center" },
      { Header: "배포 안한 디바이스(NULL)", accessor: "deploy_status_null", align: "center" },
    ],
    rows: setRowData(),
  };
}
