async function getCompany() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/companies`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function getStores() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/stores`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function getOptionsCnt() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/options_cnt`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data[0]);
  return response;
}

async function getDeployCntLastWeek() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/cnt_week`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function getDeployResult() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/result`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data[0]);
  return response;
}

async function getDeployFlag() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/deploy_flag`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function getKioskDeployFlag() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/kiosk_deploy_flag`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function getDeployMemo() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/deploy/deploy_memo`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((t) => t.json())
    .then((js) => js.data);
  return response;
}

async function updateDeployRedis(key, values) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/deploy/update_deploy_flag?key=${key}&values=${values}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(),
      }
    )
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
      })
      .catch((error) => reject(error));
  });
}

async function updateDeviceDeployOptions(rows, text) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/deploy/deploy_options?device_pkey=${rows.device_pkey}&deploy_option=${text}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
        alert(
          `${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${text ? "ON" : "OFF"}`
        );
      });
  });
}

async function getDeployLast() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/deploy/last`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
      });
  });
}

async function getDateLog(startdate, enddate) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/deploy/logs/filter?startdate=${startdate}&enddate=${enddate}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
      });
  });
}

async function getKioskDeployLog() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/deploy/kioskLogs`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
      });
  });
}

// async function updateKioskDeployLogOptions(rows, text) {
//   return new Promise((resolve, reject) => {
//     fetch(
//       `${process.env.REACT_APP_API_URL}/deploy/deploy_options?device_pkey=${rows.device_pkey}&deploy_option=${text}`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(),
//       }
//     )
//       .then((t) => t.json())
//       .then((ts) => {
//         resolve(ts.data);
//         alert(
//           `${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${text ? "ON" : "OFF"}`
//         );
//       });
//   });
// }

async function getDeployLastFilter(flag) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/deploy/last/filter?status=${flag}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    })
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
      });
  });
}

export {
  getCompany,
  getStores,
  getOptionsCnt,
  getDeployCntLastWeek,
  getDeployResult,
  getDeployFlag,
  getKioskDeployFlag,
  getDeployMemo,
  updateDeployRedis,
  updateDeviceDeployOptions,
  getDeployLast,
  getDateLog,
  getDeployLastFilter,
  getKioskDeployLog,
};
