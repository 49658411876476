import React, { useEffect, useRef, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Auth } from "aws-amplify";
import { retrieveCurrentAuthenticatedUser } from "../utils/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "",
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

async function loginUser(credentials) {
  return fetch(`${process.env.REACT_APP_API_URL}/deploy/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

export default function Signin() {
  const classes = useStyles();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    retrieveCurrentAuthenticatedUser().then((userInfo) => {
      if (userInfo.status === 200) {
        history.replace("/dashboard");
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    retrieveCurrentAuthenticatedUser().then((userInfo) => {
      if (userInfo.status === 200) {
        history.replace("/dashboard");
      }
    });

    try {
      const user = await Auth.signIn(username, password);

      if (user.username) {
        window.location.href = "/dashboard";
      }
    } catch (err) {
      alert(`로그인 실패\n${err}`);
    }

    // window.location.href = "/dashboard";
    // if ("accessToken" in response) {
    //   swal("Success", response.message, "success", {
    //     buttons: false,
    //     timer: 5000,
    //   }).then((value) => {
    //     localStorage.setItem("accessToken", response.accessToken);
    //     localStorage.setItem("user", JSON.stringify(response.user));
    //     window.location.href = "/dashboard";
    //   });
    // } else {
    //   window.location.href = "/dashboard";
    //   swal("Failed", response.message, "error", {
    //     buttons: false,
    //     timer: 5000,
    //   });
    // }
  };

  return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image} />
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
