import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {deployOptionData} from "./data/deployOptionData";
import { useEffect, useState } from "react";
import { getCompany, updateDeviceDeployOptions } from 'function/Deploy'
function companiesDeployOptions() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [filterTable, setFilterTable] = useState(false);
  const [tableData, setTableData] = useState(false);

  async function setData(){
    const company_data = await getCompany();
    deployOptionData(company_data,updateButtonHandler).then(res=>{
      setTableData(res);
    })
  } 

  function updateButtonHandler(rows, deploy_option){
    updateDeviceDeployOptions(rows, deploy_option).then(status=>{
    setRefreshKey(refreshKey+1)
   })
  }

  useEffect(()=> {
    async function fetchData() {
      setData()
    }
    fetchData()
  },[refreshKey]);

  return (
    <DashboardLayout >
      <DashboardNavbar />
      <MDBox pt={6} pb={3} >
        <Grid container spacing={6}>
          <Grid item xs={12}>        
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                회사 배포 옵션
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} m={2} >
              </Grid>
              {filterTable?(
                <DataTable
                table={filterTable}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={true}
                noEndBorder
                onButton={updateButtonHandler}
              />
              ):tableData?(
              <DataTable
                table={tableData}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={true}
                noEndBorder
                onButton={updateButtonHandler}

              />):null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default companiesDeployOptions;
