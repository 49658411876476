/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDButton from "components/MDButton";
import Checkbox from "@mui/material/Checkbox";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import Moment from 'moment';
import DeployFlag from "layouts/deployFlag";
import {updateDeployOptions} from "layouts/functions"
import NewWindow from 'react-new-window'
import {getCompany, getOptionsCnt, getDeployCntLastWeek, getDeployResult,getDeployLast, getDeployLastFilter} from 'function/Deploy'

Moment.locale('en');

function Dashboard() {
  const [ data, setData] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [optionsCnt, setOptionsCnt] = useState(0);
  const [updateOptions, setUpdateOptions] = useState(false);
  

  const [date, setDate] = useState({
    date:[],
    status_check_false:[],
    run_check_false:[],
    file_check_false:[],
    sbc_check_false:[]
  });
  
  async function fetchData(){
    getDeployResult().then(res=>{
      setData(res)
    })
    getCompany().then(res=>{
      setTableData(res)
    })
    getDeployCntLastWeek().then(res=>{
      let result = res.map(a => Moment(a.last_upd).format('YYYY-MM-DD'));
      let run_check_false = res.map(a => a.run_check_false);
      let scp_check_false = res.map(a => a.scp_check_false);
      let file_check_false = res.map(a => a.file_check_false);
      let status_check_false = res.map(a => a.status_check_false);
      setDate(date=> ({ ...date, 
        date: result,
        status_check_false:status_check_false,
        run_check_false:run_check_false,
        scp_check_false:scp_check_false,
        file_check_false:file_check_false
       }));
    })
    getOptionsCnt().then(res=>{
      setOptionsCnt(res)
    })
  } 

  useEffect(()=> {
    fetchData()    
  },[refreshKey]);

  useEffect(()=> {
    if(updateOptions){
      const interval = setInterval(() => fetchData(), 5000); // 3초마다 데이터 update
      return () => clearInterval(interval);
    }
  },[updateOptions]);

  function updateButtonHandler(rows, deploy_option){
    alert(`${rows.company_name}(${rows.company_id}) 스토어 ${rows.store_count}개 지점 배포 옵션 ${deploy_option=='true'?"ON":"OFF"}`)
    updateDeployOptions('stores', 'company_pkey', rows.company_pkey, deploy_option).then(status=>{
      setRefreshKey(refreshKey+1)
    })
  }

  async function AlertInfo(flag) {
    const data = await getDeployLastFilter(flag);
    let str = '';
    data.forEach(function(rows){
      str+=`[${rows.store_name}(${rows.store_id}) ${rows.device_id}] \n`
    })
    alert(str?str:'없음')
  }


  const FailDeviceAlert = (flag) => (
    AlertInfo(flag)
  )
  return (
    <>
    {data && tableData && optionsCnt?(
      <DashboardLayout>
        <DashboardNavbar />
        <MDButton
          color="dark"
        >
          5초 자동 갱신
        <Checkbox 
          onChange={e => {
            setUpdateOptions(e.target.checked);
          }}
        />
        </MDButton>
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="store"
                  title="전체 디바이스 수"
                  count={optionsCnt.count||'x'}
                  percentage={{
                    color: "dark",
                    label: `배포 ON : ${optionsCnt.store_count_true || 'x'}, 배포 OFF : ${optionsCnt.store_count_false || 'x'}`,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}
                  onClick={()=>FailDeviceAlert('S')}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check"
                  title="성공 디바이스(S)"
                  count={data.deploy_status_s||'x'}
                  percentage={{
                    color: "success",
                    amount: `${((data.deploy_status_s/data.deploy_all).toFixed(2)*100)||0}% 성공`,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}
                  onClick={()=>FailDeviceAlert('C')}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="warning"
                  title="대기 디바이스(C)"
                  count={data.deploy_status_c||'x'}
                  percentage={{
                    color: "warning",
                    amount: `${((data.deploy_status_c/data.deploy_all).toFixed(2)*100)||0}% 대기`,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5} 
                  onClick={()=>FailDeviceAlert('F')}>
                <ComplexStatisticsCard
                  color="error"
                  icon="error"
                  title="실패 디바이스(F)"
                  bgColor="error"
                  count={data.deploy_status_f || 'x'}
                  percentage={{
                    color: "error",
                    amount: `${((data.deploy_status_f/data.deploy_all).toFixed(2)*100)||0}% 실패`,                  
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <MDBox mb={1.5}
                  onClick={()=>FailDeviceAlert('NULL')}>
                <ComplexStatisticsCard
                  color="info"
                  icon="warning"
                  title="배포 안한 디바이스(NULL)"
                  count={data.deploy_status_null || 'x'}
                  percentage={{
                    color: "dark",
                    amount: `${((data.deploy_status_null/data.deploy_all).toFixed(2)*100).toFixed(0)||0}% 배포 안함`,

                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          {date.run_check_false, date.run_check_false, date.file_check_false, date.status_check_false ?(
            <MDBox mt={4.5}>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="상태체크 에러"
                    description=""
                    date=""
                    chart={{
                      labels: date.date,
                      datasets: { label: "status_check error", data: date.status_check_false }
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="사용중 에러"
                    description=""
                    date=""
                    chart={{
                      labels: date.date,
                      datasets: { label: "run_check error", data: date.run_check_false }
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="SCP 에러"
                    description=""
                    date=""
                    chart={{
                      labels: date.date,
                      datasets: { label: "scp_check error", data: date.scp_check_false }
                    }}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="파일 체크 에러"
                    description=""
                    date=""
                    chart={{
                      labels: date.date,
                      datasets: { label: "file_check error", data: date.file_check_false }
                    }}
                  />
                </MDBox>
              </Grid>
              
            </Grid>
          </MDBox>
          ):null}
          
          <MDBox>
            {tableData?(
              <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <Projects 
                  props={tableData}
                  onChange={updateButtonHandler}/>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DeployFlag 
                props={tableData}/>
              </Grid>
              
            </Grid>
            ):null}
            
          </MDBox> 
        </MDBox>
      </DashboardLayout>
    ):null}
    </>
  );
}

export default Dashboard;

{/* <MDBox>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6} lg={8}>
      <Projects />
    </Grid>
    <Grid item xs={12} md={6} lg={4}>
      <OrdersOverview />
    </Grid>
  </Grid>
</MDBox> */}