/* eslint-disable import/prefer-default-export */
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export async function statusData(props, onButton, onChangeDeployStatus) {
  function TextCell({ text, color, options }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color={color || "text"}
            fontWeight="medium"
          >
            {options == "err_check"
              ? text
                ? "success"
                : text == false
                ? "error"
                : "x"
              : options == "opt_check"
              ? text
                ? "on"
                : "off"
              : text || "x"}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }

  function Button({ rows, target }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDButton
            component="a"
            href="#"
            variant="gradient"
            color={rows[target] ? "white" : "success"}
            fontWeight="medium"
            onClick={() => {
              onButton(rows, !rows[target], target);
            }}
          >
            {rows[target] ? "On" : "Off"}
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }

  function StateButton({ rows, color }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDButton
            component="a"
            href="#"
            variant="gradient"
            color={rows.deploy_status === "F" ? "white" : "success"}
            fontWeight="medium"
            onClick={() => {
              if (rows.deploy_status === "F")
                onChangeDeployStatus(rows, rows.deploy_status === "F" ? "S" : "F");
            }}
          >
            {rows.deploy_status === "F" ? "Fail" : "Success"}
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }

  function setTextColor(status) {
    return status ? "success" : status == false ? "error" : "text";
  }

  function setStatusColor(status) {
    return status == "F" ? "error" : status == "C" ? "warning" : status == "S" ? "success" : "text";
  }

  function deployStatus(status) {
    return status == "S" ? "OK" : status == "C" ? "CONTINUE" : status == "F" ? "FAIL" : "NULL";
  }

  function setRowData() {
    const data = props;
    const array = [];
    data.forEach((rows) => {
      const rowObj = {
        store_name: (
          <TextCell
            text={
              <div>
                <div>{rows.store_name}</div>
                <div>{`( ${rows.store_id} )`}</div>{" "}
              </div>
            }
            color="text"
          />
        ),
        store_id: <TextCell text={rows.store_id} name={rows.store_name} color="text" />,
        device_id: <TextCell text={rows.device_id} color="text" />,
        last_upd: <TextCell text={rows.last_upd} color="text" />,
        file_name: <TextCell text={rows.file_name} color="text" />,
        identity: <TextCell text={rows.identity} color="text" />,
        file_check: (
          <TextCell
            text={rows.file_check}
            color={setTextColor(rows.file_check)}
            options="err_check"
          />
        ),
        run_check: (
          <TextCell
            text={rows.run_check}
            color={setTextColor(rows.run_check)}
            options="err_check"
          />
        ),
        scp_check: (
          <TextCell
            text={rows.scp_check}
            color={setTextColor(rows.scp_check)}
            options="err_check"
          />
        ),
        status_check: (
          <TextCell
            text={rows.status_check}
            color={setTextColor(rows.status_check)}
            options="err_check"
          />
        ),
        deploy_status: <StateButton rows={rows} />,
        deploy_option: <Button rows={rows} target="deploy_option" />,
        device_kiosk_deploy_option: (
          <Button
            rows={rows}
            text={rows.device_kiosk_deploy_option}
            target="device_kiosk_deploy_option"
          />
        ),
      };
      array.push(rowObj);
    });
    return array;
  }

  return {
    columns: [
      { Header: "지점명", accessor: "store_name", align: "center" },
      { Header: "디바이스 ID", accessor: "device_id", align: "center" },
      { Header: "배포 날짜", accessor: "last_upd", align: "center" },
      { Header: "파일명", accessor: "file_name", align: "center" },
      { Header: "배포 타입", accessor: "identity", align: "center" },
      { Header: "배포 상태", accessor: "deploy_status", align: "center" },
      { Header: "파일 체크 오류", accessor: "file_check", align: "center" },
      { Header: "고객 사용중 오류", accessor: "run_check", align: "center" },
      { Header: "SCP 오류", accessor: "scp_check", align: "center" },
      { Header: "상태 체크 오류", accessor: "status_check", align: "center" },
      { Header: "배포 옵션", accessor: "deploy_option", align: "center" },
      { Header: "키오스크 배포 옵션", accessor: "device_kiosk_deploy_option", align: "center" },
    ],
    rows: setRowData(),
  };
}
