/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {updateDeployOptions} from "layouts/functions"


export function tabledata(props) {  
  const TextCell = ({ text, color, options }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography component="a" href="#" variant="caption" color={color || "text"} fontWeight="medium">
            {options == "err_check"?(
              text?"success":text==false?"error":"x"
            ):options == 'opt_check'?(
              text?'on':'off'
            ):text?text:'X'}
          </MDTypography>
      </MDBox>
    </MDBox>
  );
  // function updateButtonHandler(rows, deploy_option){
  //   alert(`${rows.company_name}(${rows.company_id}) 스토어 ${rows.store_count}개 지점 배포 옵션 ${deploy_option=='true'?"ON":"OFF"}`)
  //   updateDeployOptions('stores', 'company_pkey', rows.company_pkey, deploy_option).then(status=>{
  //     onChange();
  //   })
  //  }

  const Button = ({ rows, color }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDButton component="a" href="#" variant="gradient" color={rows.deploy_option?"white":"success"} fontWeight="medium" onClick={()=>props.onChange(rows, 'true')}>
            {"ON"}
          </MDButton>
      </MDBox>
      <MDBox ml={2} lineHeight={1}>
        <MDButton component="a" href="#" variant="gradient" color={rows.deploy_option?"white":"info"} fontWeight="medium" onClick={()=>props.onChange(rows, 'false')}>
            {"OFF"}
        </MDButton>
      </MDBox>
    </MDBox>
  );
  function setRowData() {
    const data = props.props;
    let array = [];
    data.forEach(function(rows){
      var rowObj = {
        company_name: <TextCell text={rows.company_name} color={"text"} />,
        store_count: <TextCell text={rows.store_count} color={"text"} />,
        store_count_true: <TextCell text={rows.store_count_true}  />,
        store_count_false: <TextCell text={rows.store_count_false}  />,
        change_deploy_option: <Button rows={rows}/>
      }
      array.push(rowObj)
    })
    return array
  }

  return {
    columns: [
      { Header: "회사", accessor: "company_name", width: "30%", align: "left" },
      { Header: "전체 지점 수", accessor: "store_count", align: "center" },
      { Header: "배포 ON 지점", accessor: "store_count_true", align: "center" },
      { Header: "배포 OFF 지점", accessor: "store_count_false", align: "center" },
      { Header: "전지점 배포 ON/OFF 변경", accessor: "change_deploy_option", align: "center" },
    ],

    rows: setRowData()
  };
}
