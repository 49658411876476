export function updateDeployOptions(table, key_column, pkey, deploy_option) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/deploy/deploy_options?table=${table}&key_column=${key_column}&pkey=${pkey}&deploy_option=${deploy_option}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
        //   alert(`${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${text?"ON":"OFF"}`)
      });
  });
}

// update_deploy_status
// "S"
export function updateDeployStatus(store_id, device_id, status) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/deploy/update_deploy_status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ store_id, device_id, deploy_status: status }),
    })
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
        //   alert(`${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${text?"ON":"OFF"}`)
      });
  });
}

export function updateKisokDeployOptions(table, key_column, pkey, deploy_option) {
  console.log(table, key_column, pkey, deploy_option);
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/deploy/kiosk_deploy_options?table=${table}&key_column=${key_column}&pkey=${pkey}&deploy_option=${deploy_option}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    )
      .then((t) => t.json())
      .then((ts) => {
        resolve(ts.data);
        //   alert(`${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${text?"ON":"OFF"}`)
      });
  });
}
