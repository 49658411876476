import { Auth } from "@aws-amplify/auth";

const signUp = async ({ username, name, password, email, phone, customAttr = {}, groupName }) => {
  // https://aws-amplify.github.io/docs/js/authentication#using-components-in-react--react-native
  try {
    const signUpRes = await Auth.signUp({
      username,
      password,
      attributes: {
        name,
        email,
        phone_number: `+82${phone}`,
        ...customAttr,
        // other custom attributes ex) 'custom:attribute_name': 'blahblah'
        // cognito에 없는 attribute를 강제로 넣으면 에러.
      },
      validationData: [], // optional
      clientMetadata: {
        GroupName: groupName,
      }, // optional
    });
    return {
      status: 200,
      data: signUpRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const confirmSignUp = async ({ name, confirmCode, userlevel }) => {
  try {
    const confirmSignUpRes = await Auth.confirmSignUp(name, confirmCode, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
      clientMetadata: {
        GroupName: userlevel,
      },
    });
    return {
      status: 200,
      data: confirmSignUpRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const signIn = async ({ userId, userPw }) => {
  try {
    const user = await Auth.signIn(userId, userPw);

    return {
      status: 200,
      data: user,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const signOut = async () => {
  try {
    const signOuttedObj = await Auth.signOut();
    return {
      status: 200,
      data: signOuttedObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const retrieveCurrentAuthenticatedUser = async (props) => {
  try {
    // https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
    // https://github.com/aws-amplify/amplify-js/issues/2534
    const { bypassCache } = props || false;
    const authUserObj = await Auth.currentAuthenticatedUser({
      bypassCache, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });
    return {
      status: 200,
      data: authUserObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const retrieveCurrentSession = async () => {
  try {
    // https://aws-amplify.github.io/docs/js/authentication#retrieve-current-session
    const sessionObj = await Auth.currentSession();
    const idToken = sessionObj && sessionObj.getIdToken();
    const jwtToken = idToken && idToken.getJwtToken();
    return {
      status: 200,
      data: sessionObj,
      authToken: jwtToken,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
/**
 * @name getAuthHeader
 * @param {{username: string}} username
 * @description API Gateway Authorizer 통신을 위한 token 얻기
 * @usage
 *  const authHeader = await getAuthHeader({ username: username })
 */
const getAuthHeader = async ({ username }) => {
  const token = await retrieveCurrentSession();
  const debugMode = process.env && process.env.LOCAL;
  const authToken = token.status === 200 && token.authToken;
  const authHeader = debugMode ? JSON.stringify({ userId: username }) : `Bearer ${authToken}`;
  return authHeader;
};
const verifyUser = async ({ attr }) => {
  try {
    // To initiate the process of verifying the attribute like 'phone_number' or 'email'
    const verifyObj = await Auth.verifyCurrentUserAttribute(attr);
    return {
      status: 200,
      data: verifyObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const verifyUserConfirm = async ({ attr, authCode }) => {
  try {
    // To verify attribute with the code
    const verifyConfirmObj = await Auth.verifyCurrentUserAttributeSubmit(attr, authCode);
    return {
      status: 200,
      data: verifyConfirmObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const resendSignUp = async ({ username }) => {
  try {
    const resentObj = await Auth.resendSignUp(username);
    return {
      status: 200,
      data: resentObj,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const changePw = async ({ user, oldPw, newPw }) => {
  // https://aws-amplify.github.io/docs/js/authentication#change-password
  try {
    const authUserObj = await Auth.currentAuthenticatedUser();
    const changePwRes = await Auth.changePassword(authUserObj, oldPw, newPw);
    return {
      status: 200,
      data: changePwRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const forgotPw = async ({ username }) => {
  // https://aws-amplify.github.io/docs/js/authentication#forgot-password
  try {
    const forgotPwRes = await Auth.forgotPassword(username);
    return {
      status: 200,
      data: forgotPwRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const confirmForgotPw = async ({ username, code, newPw, clientMetadata }) => {
  // https://aws-amplify.github.io/docs/js/authentication#forgot-password
  try {
    const forgotPwSubmitRes = await Auth.forgotPasswordSubmit(
      username,
      code,
      newPw,
      clientMetadata
    );
    return {
      status: 200,
      data: forgotPwSubmitRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};
const updateUserAttr = async ({ userAttr, updateAttr }) => {
  // https://aws-amplify.github.io/docs/js/authentication#working-with-user-attributes
  try {
    const updateRes = await Auth.updateUserAttributes(userAttr, updateAttr);
    return {
      status: 200,
      data: updateRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

const adminupdateUserAttr = async ({ userAttr, userPoolId, userName }) => {
  // https://aws-amplify.github.io/docs/js/authentication#working-with-user-attributes
  try {
    const updateRes = await Auth.adminUpdateUserAttributes(userAttr, userPoolId, userName);
    return {
      status: 200,
      data: updateRes,
    };
  } catch (e) {
    return {
      status: 500,
      data: e,
    };
  }
};

export {
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  retrieveCurrentAuthenticatedUser,
  retrieveCurrentSession,
  verifyUser,
  verifyUserConfirm,
  resendSignUp,
  changePw,
  forgotPw,
  confirmForgotPw,
  updateUserAttr,
  getAuthHeader,
  adminupdateUserAttr,
};
