import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { deployOptionData } from "./data/deployOptionData";
import { updateDeployOptions, updateKisokDeployOptions } from "../functions";
import { getStores } from "../../function/Deploy";

function storesDeployOptions() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [filterTable, setFilterTable] = useState(false);
  const [tableData, setTableData] = useState(false);
  const [allCompany, setAllCompany] = useState(false);
  const [allStore, setAllStore] = useState(false);
  const [filterStore, setFilterStore] = useState("");
  const [filterCompany, setFilterCompany] = useState("");

  async function getData() {
    const data = await getStores();

    const res = await deployOptionData(data, updateButtonHandler);
    setAllCompany(false);
    setAllStore(false);
    setFilterStore(null);
    setFilterCompany(null);
    return res;
  }

  async function getCompany(new_data) {
    if (new_data) {
      const tmp_company = [...new Set(new_data.map((it) => it.company_id.props))];
      const companies = tmp_company.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllCompany(companies);
    }
  }

  async function getStore(new_data) {
    if (new_data) {
      const tmp_store = [...new Set(new_data.map((it) => it.store_id.props))];
      const stores = tmp_store.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllStore(stores);
    }
  }

  const filterData = (data, keyword, values) =>
    data.filter((data) => String(data[keyword].props.text) == values);

  async function filter_data(company_id, store_id) {
    return new Promise((resolve, reject) => {
      if (company_id && store_id) {
        resolve(
          filterData(filterData(tableData.rows, "company_id", company_id), "store_id", store_id)
        );
      } else if (company_id) resolve(filterData(tableData.rows, "company_id", company_id));
      else if (store_id) resolve(filterData(tableData.rows, "store_id", store_id));
    });
  }

  async function setCompanyhandler(values) {
    if (values) {
      const data = await filter_data(values.text, filterStore);
      setFilterCompany(values.text);
      setFilterTable({ ...tableData, rows: data });
      getStore(data);
    } else {
      if (filterStore) {
        const data = await filter_data("", filterStore);
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterStore("");
    }
  }

  async function setStorehandler(values) {
    if (values) {
      const data = await filter_data(filterCompany, values.text);
      setFilterStore(values.text);
      setFilterTable({ ...tableData, rows: data });
    } else {
      if (filterCompany) {
        const data = await filter_data(filterCompany, "");
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterStore("");
    }
  }

  async function setData() {
    const new_data = await getData();
    getCompany(new_data.rows);
    getStore(new_data.rows);
    setTableData(new_data);
    setFilterTable(false);
  }

  function updateButtonHandler(rows, deploy_option, key) {
    if (key === "deploy_option") {
      alert(
        `${rows.store_id}(${rows.store_name}) 배포 옵션 ${deploy_option == "true" ? "ON" : "OFF"}`
      );
      updateDeployOptions("stores", "store_pkey", rows.store_pkey, deploy_option).then((status) => {
        setRefreshKey(refreshKey + 1);
      });
    } else if (key === "kiosk_deploy_option") {
      alert(
        `${rows.store_id}(${rows.store_name}) 키오스크 배포 옵션 ${
          deploy_option == "true" ? "ON" : "OFF"
        }`
      );
      updateKisokDeployOptions("stores", "store_pkey", rows.store_pkey, deploy_option).then(
        (status) => {
          setRefreshKey(refreshKey + 1);
        }
      );
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData();
    }
    fetchData();
  }, [refreshKey]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  지점별 배포 옵션
                </MDTypography>
                <MDTypography variant="h6" color="white">
                  (지점의 한 디바이스라도 오류 발생 시 Fail)
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} m={2}>
                <Grid item>
                  <Autocomplete
                    id="combo box"
                    key={allCompany}
                    options={Object.values(allCompany)}
                    getOptionSelected={(option, value) => option.text === value.text}
                    getOptionLabel={(option) =>
                      option.text ? `${option.text}(${option.name})` : ""
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="회사 필터" variant="outlined" />
                    )}
                    onChange={(event, values) => setCompanyhandler(values)}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    id="combo box"
                    key={allStore}
                    options={Object.values(allStore)}
                    getOptionSelected={(option, value) => option.text === value.text}
                    getOptionLabel={(option) =>
                      option.text ? `${option.text}(${option.name})` : ""
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="스토어 필터" variant="outlined" />
                    )}
                    onChange={(option, values) => setStorehandler(values)}
                  />
                </Grid>
              </Grid>
              {filterTable ? (
                <DataTable
                  table={filterTable}
                  isSorted={false}
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                  onButton={updateButtonHandler}
                />
              ) : tableData ? (
                <DataTable
                  table={tableData}
                  isSorted={false}
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                  onButton={updateButtonHandler}
                />
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default storesDeployOptions;
