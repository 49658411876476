import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

import { getDeployLast, getDeployFlag } from "function/Deploy";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { updateDeployOptions, updateDeployStatus, updateKisokDeployOptions } from "../functions";
import { statusData } from "./data/statusData";

function StatusTables() {
  // redis deploy_flag (1: 자동배포 o, 0: 자동배포 x)
  const [refreshKey, setRefreshKey] = useState(0);
  const [filterTable, setFilterTable] = useState(false);
  const [tableData, setTableData] = useState(false);

  const [allStore, setAllStore] = useState(false);
  const [allDevice, setAllDevice] = useState(false);
  const [filterStore, setFilterStore] = useState("");
  const [filterDevice, setFilterDevice] = useState("");

  const [kioskDiployFilter, setKioskDiployFilter] = useState("All");

  useEffect(() => {
    const onload = async () => {
      if (tableData && kioskDiployFilter !== "no") {
        await setData();
        if (kioskDiployFilter === "All") {
          setFilterTable(false);
        } else {
          setFilterTable({
            ...tableData,
            rows: filterData(tableData.rows, "device_kiosk_deploy_option", `${kioskDiployFilter}`),
          });
        }
      }
    };

    onload();
  }, [kioskDiployFilter]);

  async function getData() {
    const data = await getDeployLast();

    const res = await statusData(data, updateButtonHandler, onChangeDeployStatus);
    setAllStore(false);
    setAllDevice(false);
    setFilterStore(null);
    setFilterDevice("");
    return res;
  }

  async function getStore(new_data) {
    if (new_data) {
      const tmp_store = [...new Set(new_data.map((it) => it.store_id.props))];
      const stores = tmp_store.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllStore(stores);
    }
  }

  async function getDevice(new_data) {
    if (new_data) {
      const tmp_devices = [...new Set(new_data.map((it) => it.device_id.props))];
      const devices = tmp_devices.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllDevice(devices);
    }
  }

  const filterData = (data, keyword, values) =>
    data.filter((data) => String(data[keyword].props.text) == values);

  async function filter_data(store_id, device_id) {
    return new Promise((resolve, reject) => {
      if (store_id && device_id)
        resolve(
          filterData(filterData(tableData.rows, "store_id", store_id), "device_id", device_id)
        );
      else if (store_id) resolve(filterData(tableData.rows, "store_id", store_id));
      else if (device_id) resolve(filterData(tableData.rows, "device_id", device_id));
    });
  }

  async function setStorehandler(values) {
    setKioskDiployFilter("no");

    if (values) {
      const data = await filter_data(values.text, filterDevice);
      setFilterStore(values.text);
      setFilterTable({ ...tableData, rows: data });
      getDevice(data);
    } else {
      if (filterDevice) {
        const data = await filter_data("", filterDevice);
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterStore("");
    }
  }

  async function setDevicehandler(values) {
    setKioskDiployFilter("no");

    if (values) {
      const data = await filter_data(filterStore, values.text);
      setFilterDevice(values.text);
      setFilterTable({ ...tableData, rows: data });
    } else {
      if (filterStore) {
        const data = await filter_data(filterStore, "");
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterDevice("");
    }
  }

  async function setData() {
    const new_data = await getData();
    getStore(new_data.rows);
    getDevice(new_data.rows);
    setTableData(new_data);
    setFilterTable(false);
  }

  function updateButtonHandler(rows, deploy_option, target) {
    if (target === "deploy_option") {
      alert(
        `${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포 옵션 ${
          deploy_option ? "ON" : "OFF"
        }`
      );
      updateDeployOptions("devices", "device_pkey", rows.device_pkey, deploy_option).then(
        (status) => {
          setRefreshKey(refreshKey + 1);
        }
      );
    } else if (target === "device_kiosk_deploy_option") {
      alert(
        `${rows.store_id}(${rows.store_name}) ${rows.device_id} 키오스크 배포 옵션 ${
          deploy_option ? "ON" : "OFF"
        }`
      );
      updateKisokDeployOptions("devices", "device_pkey", rows.device_pkey, deploy_option).then(
        (status) => {
          setRefreshKey(refreshKey + 1);
        }
      );
    }
  }

  function onChangeDeployStatus(rows, status) {
    alert(
      `${rows.store_id}(${rows.store_name}) ${rows.device_id} 배포상태 ${
        status === "S" ? "SUCCESS" : "FAIL"
      }`
    );
    updateDeployStatus(rows.store_id, rows.device_id, status).then((e) => {
      setRefreshKey(refreshKey + 1);
    });
  }

  useEffect(() => {
    async function fetchData() {
      setData();
    }
    fetchData();
  }, [refreshKey]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  디바이스 배포 상태 (최신 배포)
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} m={2}>
                <Grid item>
                  <div>
                    <div style={{ fontSize: 12 }}>키오스크 배포 옵션 필터</div>
                    <ButtonGroup size="small" variant="contained">
                      <Button
                        style={{
                          backgroundColor:
                            kioskDiployFilter === "All" || kioskDiployFilter === "no"
                              ? "skyblue"
                              : "white",
                        }}
                        onClick={() => setKioskDiployFilter("All")}
                      >
                        전체
                      </Button>
                      <Button
                        style={{
                          backgroundColor: kioskDiployFilter === true ? "skyblue" : "white",
                        }}
                        onClick={() => setKioskDiployFilter(true)}
                      >
                        ON
                      </Button>
                      <Button
                        style={{
                          backgroundColor: kioskDiployFilter === false ? "skyblue" : "white",
                        }}
                        onClick={() => setKioskDiployFilter(false)}
                      >
                        OFF
                      </Button>
                    </ButtonGroup>
                  </div>
                </Grid>

                <Grid item>
                  <Autocomplete
                    id="combo box"
                    key={allStore}
                    options={Object.values(allStore)}
                    getOptionSelected={(option, value) => option.text === value.text}
                    getOptionLabel={(option) =>
                      option.text ? `${option.name}(${option.text})` : ""
                    }
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="스토어 필터" variant="outlined" />
                    )}
                    onChange={(event, values) => setStorehandler(values)}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    id="combo box"
                    key={allDevice}
                    options={Object.values(allDevice)}
                    getOptionLabel={(option) => (option.text ? option.text : "")}
                    getOptionSelected={(option, value) => option.text === value.text}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="디바이스 필터" variant="outlined" />
                    )}
                    onChange={(option, values) => setDevicehandler(values)}
                  />
                </Grid>
              </Grid>

              {filterTable ? (
                <DataTable
                  table={filterTable}
                  isSorted={false}
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                />
              ) : tableData ? (
                <DataTable
                  table={tableData}
                  isSorted={false}
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                />
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default StatusTables;
