import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { logData } from "layouts/LogTables/data/logData";

// daterangepicker
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, InputLabel, Input } from "@material-ui/core";
import { getDateLog } from "function/Deploy";

registerLocale("ko", ko);

const moment = require("moment");

function LogTables() {
  const [tableData, setTableData] = useState(false);
  const [filterTable, setFilterTable] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [allStore, setAllStore] = useState(false);
  const [allCompany, setAllCompany] = useState(false);
  const [filterStore, setFilterStore] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [selectedTime, SetSelectedTime] = useState({
    startDate: new Date(moment().startOf("month").toDate()),
    endDate: new Date(moment().startOf("day").toDate()),
  });

  async function getData(startdate, enddate) {
    const data = await getDateLog(
      moment(startdate).format("YYYYMMDD"),
      moment(enddate).format("YYYYMMDD")
    );
    const res = await logData(data);
    setAllStore(false);
    setAllCompany(false);
    setFilterStore(null);
    setFilterCompany(null);
    return res;
  }

  const SelcetDateCallback = (event, values) => {
    SetSelectedTime({ startDate: values.startDate.toDate(), endDate: values.endDate.toDate() });
  };

  async function getCompany(new_data) {
    if (new_data) {
      const tmp_company = [...new Set(new_data.map((it) => it.company_id.props))];
      const companies = tmp_company.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllCompany(companies);
    }
  }

  async function getStore(new_data) {
    if (new_data) {
      const tmp_store = [...new Set(new_data.map((it) => it.store_id.props))];
      const stores = tmp_store.reduce(
        (items, item) => (items.find((x) => x.text === item.text) ? [...items] : [...items, item]),
        []
      );
      setAllStore(stores);
    }
  }

  const filterData = (data, keyword, values) =>
    data.filter((data) => String(data[keyword].props.text) == values);

  async function filter_data(company_id, store_id) {
    return new Promise((resolve, reject) => {
      if (company_id && store_id) {
        resolve(
          filterData(filterData(tableData.rows, "company_id", company_id), "store_id", store_id)
        );
      } else if (company_id) resolve(filterData(tableData.rows, "company_id", company_id));
      else if (store_id) resolve(filterData(tableData.rows, "store_id", store_id));
    });
  }

  async function setCompanyhandler(values) {
    if (values) {
      const data = await filter_data(values.text, filterStore);
      setFilterCompany(values.text);
      setFilterTable({ ...tableData, rows: data });
      getStore(data);
    } else {
      if (filterStore) {
        const data = await filter_data("", filterStore);
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterStore("");
    }
  }

  async function setStorehandler(values) {
    if (values) {
      const data = await filter_data(filterCompany, values.text);
      setFilterStore(values.text);
      setFilterTable({ ...tableData, rows: data });
    } else {
      if (filterCompany) {
        const data = await filter_data(filterCompany, "");
        setFilterTable({ ...tableData, rows: data });
      } else {
        setRefreshKey((oldKey) => oldKey + 1);
      }
      setFilterStore("");
    }
  }

  async function setData() {
    const new_data = await getData(selectedTime.startDate, selectedTime.endDate);
    getStore(new_data.rows);
    getCompany(new_data.rows);
    setTableData(new_data);
    setFilterTable(false);
  }

  useEffect(() => {
    setData();
  }, [selectedTime, refreshKey]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    기간별 배포 로그
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <Grid container spacing={3} m={2} xs={8}>
                    <Grid item>
                      <DateRangePicker initialSettings={selectedTime} onApply={SelcetDateCallback}>
                        <input
                          type="text"
                          className="form-control"
                          value={`${moment(selectedTime.startDate).format("YYYY/MM/DD")} - ${moment(
                            selectedTime.endDate
                          ).format("YYYY/MM/DD")}`}
                          style={{ display: "block", width: 300, height: 20, padding: 25 }}
                        />
                      </DateRangePicker>
                    </Grid>

                    <Grid item>
                      <Autocomplete
                        id="combo box"
                        key={allCompany}
                        options={Object.values(allCompany)}
                        getOptionSelected={(option, value) => option.text === value.text}
                        getOptionLabel={(option) =>
                          option.text ? `${option.text}(${option.name})` : ""
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="회사 필터" variant="outlined" />
                        )}
                        onChange={(event, values) => setCompanyhandler(values)}
                      />
                    </Grid>
                    <Grid item>
                      <Autocomplete
                        id="combo box"
                        key={allStore}
                        options={Object.values(allStore)}
                        getOptionLabel={(option) =>
                          option.id ? `${option.id}(${option.name})` : ""
                        }
                        getOptionSelected={(option, value) => option.id === value.id}
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="스토어 필터" variant="outlined" />
                        )}
                        onChange={(event, values) => setStorehandler(values)}
                      />
                    </Grid>
                  </Grid>
                  {filterTable ? (
                    <DataTable
                      table={filterTable}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries
                      canSearch={false}
                      noEndBorder
                    />
                  ) : tableData ? (
                    <DataTable
                      table={tableData}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries
                      canSearch={false}
                      noEndBorder
                    />
                  ) : null}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default LogTables;
